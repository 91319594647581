import React from 'react'

export default function Home() {
    return (
        <>

            <div className="carousel slide" data-bs-ride="carousel" id="slider1">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#slider1" data-bs-slide-to="0" className="active" aria-current="true"
                        aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#slider1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#slider1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" style={{ background: "url(image/slider1.jpg) center", backgroundSize: "cover" }}>
                        <div className="carousel-item-inner">
                            <h6 className="text-white mb-20 text-uppercase text-center">Discover the Colorful World</h6>
                            <h1 className="text-uppercase text-white">New Adventure</h1>
                            <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp <br />
                                or incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                            <a href="#" className="text-uppercase slider-btn">Discover Now</a>
                        </div>
                    </div>
                    <div className="carousel-item" style={{ background: "url(image/slider2.jpg) center", backgroundSize: "cover" }}>
                        <div className="carousel-item-inner">
                            <h6 className="text-white mb-20 text-uppercase text-center">Discover the Colorful World</h6>
                            <h1 className="text-uppercase text-white">New Adventure</h1>
                            <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp <br />
                                or incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                            <a href="#" className="text-uppercase slider-btn">Discover Now</a>
                        </div>

                    </div>
                    <div className="carousel-item" style={{ background: "url(image/slider3.jpg) center", backgroundSize: "cover" }}>
                        <div className="carousel-item-inner">
                            <h6 className="text-white mb-20 text-uppercase text-center">Discover the Colorful World</h6>
                            <h1 className="text-uppercase text-white">New Adventure</h1>
                            <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp <br />
                                or incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                            <a href="#" className="text-uppercase slider-btn">Discover Now</a>
                        </div>

                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#slider1" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#slider1" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden"></span>
                </button>
            </div>
            {/* slider end */}


            {/* about section Start */}
            <div className="about-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1>Why Choose Us Your Fitness Builder</h1>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>
                    </div>
                    <div className="row about-inner">
                        <div className="col-lg-6">
                            <img src="image/about-img.jpg" alt="" className="img-fluid wow animate__animated animate__fadeInLeft animate__delay-0.5s" />
                        </div>
                        <div className="col-lg-6 wow animate__animated animate__fadeInRight animate__delay-0.5s">
                            <h3>We Realize that <br />there are reduced <br />Wastege Stand out</h3>
                            <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards
                                especially in the workplace. Thats why its crucial that, as women.</p>
                            <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards
                                especially in the workplace. Thats why its crucial that, as women. Thats why its crucial that, as women.</p>
                            <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards
                                especially in the workplace. Thats why its crucial that, as women.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* about section end */}


            {/* service section start */}
            <section className="about-section" id="secvice">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1>Some Features that Made us Unique</h1>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container fe-section">
                <div className="row ">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h4>Expert Technicians</h4>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Professional Service</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Great Support</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Technical Skills</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Highly Recomended</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Positive Reviews</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* service section end */}

            {/* blog section start */}
            <section className="about-section">
                <div className="container">
                    <div className="row text-center mb-5">
                        <div className="col-12">
                            <h1>News & Blogs</h1>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-01.jpg" className="card-img-top" alt="blog01" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">What we promise high quality IT Agency Services</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-02.jpg" className="card-img-top" alt="blog02" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">Write a Better security method</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-03.jpg" className="card-img-top" alt="blog03" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">Tech experiences that connect us</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-04.jpg" className="card-img-top" alt="blog04" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">A Complete Guide to Cybersecurity</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* blog section end */}


            {/* Contact us start */}
            <div className="about-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1>If you need, Just drop us a line</h1>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>
                    </div>
                    <form>
                        <div className="row form-outer">
                            <div className="col-lg-6 form-group">
                                <input type="text" placeholder="Enter Your Name" className="form-control" />
                                <input type="text" placeholder="Enter email address" className="form-control" />
                                <input type="text" placeholder="Enter Your Subject" className="form-control" />
                            </div>
                            <div className="col-lg-6">
                                <textarea className="form-control" placeholder="Message"></textarea>
                                <button className="btn btn-primary">Send Message <i className="fa-solid fa-arrow-right-long"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Contact us end */}
        </>
    )
}
