import React from 'react'

export default function Footer() {
  return (
    <section>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h5>About us</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
              dolore magna aliqua.</p>
            <p>Copyright ©2024 All rights reserved | This template is made by</p>
            <a href="https://kumrawatweb.site/" target="_blank">Kumrawat Web Servics</a>
          </div>
          <div className="col-lg-5">
            <h5>Newsletter</h5>
            <p>Stay update with our latest</p>
            <p>
            <form className="d-flex">
              <input type="text" className="form-control" placeholder="Enter Email" />
              <button className="btn btn-primary"><i className="fa-solid fa-arrow-right-long"></i></button>
            </form>
            </p>

          </div>
          <div className="col-lg-2">
            <h5>Follow us</h5>
            <p>Let us be social</p>
            <div className="s-link">
              <i className="fa-brands fa-facebook-f"></i>
              <i className="fa-brands fa-twitter"></i>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
  )
}
