import React from 'react'

export default function Contact() {
  return (
    <div className="about-section">
        <div className="container">
            <div className="row text-center">
                <div className="col-12">
                    <h1>If you need, Just drop us a line</h1>
                    <p>Who are in extremely love with eco friendly system.</p>
                </div>
            </div>
            <form>
                <div className="row form-outer">
                    <div className="col-lg-6 form-group">
                        <input type="text" placeholder="Enter Your Name" className="form-control" />
                        <input type="text" placeholder="Enter email address" className="form-control" />
                        <input type="text" placeholder="Enter Your Subject" className="form-control" />
                    </div>
                    <div className="col-lg-6">
                        <textarea className="form-control" placeholder="Message"></textarea>
                        <button className="btn btn-primary">Send Message <i className="fa-solid fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}
