import React from 'react'

export default function Service() {
    return (
        <>
            <section className="about-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1>Some Services that Made us Unique</h1>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container fe-section">
                <div className="row ">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h4>Expert Technicians</h4>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Professional Service</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Great Support</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Technical Skills</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Highly Recomended</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="fe-section-item">
                            <h5>Positive Reviews</h5>
                            <p>Usage of the Internet is becoming more common due to rapid
                                advancement of technology and power.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
