import React from 'react'

export default function Blog() {
  return (
    <section className="about-section">
                <div className="container">
                    <div className="row text-center mb-5">
                        <div className="col-12">
                            <h1>News & Blogs</h1>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-01.jpg" className="card-img-top" alt="blog01" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">What we promise high quality IT Agency Services</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#1" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-02.jpg" className="card-img-top" alt="blog02" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">Write a Better security method</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#1" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-03.jpg" className="card-img-top" alt="blog03" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">Tech experiences that connect us</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#1" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="image/blog-04.jpg" className="card-img-top" alt="blog04" />
                                <div className="card-body mb-5">
                                    <h5 className="card-title">A Complete Guide to Cybersecurity</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#1" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  )
}
