import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <header className="container-fluid menu fixed-top">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="navbar navbar-expand-lg">
                            <Link to="/" className="navbar-brand">
                                <img src="image/Logo.png" alt="" width="200" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link active">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/about" className="nav-link">About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/service" className="nav-link">Services</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/blog" className="nav-link">Blog</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact" className="nav-link">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}
