import React from 'react'

export default function About() {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <h1>Why Choose Us Your Fitness Builder</h1>
            <p>Who are in extremely love with eco friendly system.</p>
          </div>
        </div>
        <div className="row about-inner">
          <div className="col-lg-6">
            <img src="image/about-img.jpg" alt="" className="img-fluid wow animate__animated animate__fadeInLeft animate__delay-0.5s" />
          </div>
          <div className="col-lg-6 wow animate__animated animate__fadeInRight animate__delay-0.5s">
            <h3>We Realize that <br />there are reduced <br />Wastege Stand out</h3>
            <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards
              especially in the workplace. Thats why its crucial that, as women.</p>
            <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards
              especially in the workplace. Thats why its crucial that, as women. Thats why its crucial that, as women.</p>
            <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards
              especially in the workplace. Thats why its crucial that, as women.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
